import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import myImage from './img/html_logo.png';
import myImage2 from './img/css_logo.png';
import myImage3 from './img/javascript_logo.png';
import myImage4 from './img/php_logo.png';
import myImage5 from './img/mysql_logo.png';
import myImage6 from './img/react_logo.png';
import myImage7 from './img/nodejs_logo.png';
import myImage8 from './img/git_logo.png';
import myImage9 from './img/boostrap_logo.png';
import myImage10 from './img/tailwind_logo.png';

export default function Example() {

    return (

        <div className="bg-white py-24 sm:py-32">

            <div className="mx-auto max-w-5xl px-1 lg:px-1">

                <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
                    Herramientas front end / back-end utilizadas en este proyecto
                </h2>

                <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    <img
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    src={myImage}
                    alt="Transistor"
                    width={158}
                    height={48}
                    />
                    <img
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    src={myImage2}
                    alt="Reform"
                    width={158}
                    height={48}
                    />
                    <img
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    src={myImage3}
                    alt="Tuple"
                    width={158}
                    height={48}
                    />
                    <img
                    className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                    src={myImage4}
                    alt="SavvyCal"
                    width={158}
                    height={48}
                    />
                    <img
                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    src={myImage5}
                    alt="Statamic"
                    width={158}
                    height={48}
                    />
                    <img
                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    src={myImage6}
                    alt="Statamic"
                    width={158}
                    height={48}
                    />
                    <img
                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    src={myImage7}
                    alt="Statamic"
                    width={158}
                    height={48}
                    />
                    <img
                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    src={myImage8}
                    alt="Statamic"
                    width={148}
                    height={38}
                    />
                    <img
                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    src={myImage9}
                    alt="Statamic"
                    width={158}
                    height={48}
                    />
                    <img
                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    src={myImage10}
                    alt="Statamic"
                    width={158}
                    height={48}
                    />
                    
                </div>

            </div>

        </div>
      
    )

}