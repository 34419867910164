import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import myImage from './img/Captura2.PNG';

import MiNuevoComponente1 from './index1.js'

import MiNuevoComponente from './index2.js'
import MiNuevoComponente2 from './index3.js'
import MiNuevoComponente3 from './index4.js'
import MiNuevoComponente4 from './index5.js'
import MiNuevoComponente5 from './index6.js'

export default function Example() {

    return (

        <div className="bg-gray-900">

            <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">

                <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl   sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                    
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                        aria-hidden="true"
                    >
                        
                        <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />

                        <defs>

                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                <stop stopColor="#7775D6" />
                                <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>

                        </defs>

                    </svg>

                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">

                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl"><br />
                        
                            Comience a usar nuestra aplicación hoy.

                        </h2>

                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla.
                        </p>

                        <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">

                            <a
                            href="#"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >

                                Get started

                            </a>

                            <a href="#" className="text-sm font-semibold leading-6 text-white">
                                Learn more <span aria-hidden="true">→</span>
                            </a>

                        </div>

                    </div>

                    <div className="relative mt-16 h-80 lg:mt-8">

                        <img
                            className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                            src={myImage}
                            alt="App screenshot"
                            width={1920}
                            height={1080}
                        />

                    </div>

                </div>

            </div>

        </div>

    )

}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Example />)

const rootMiNuevoComponente1 = ReactDOM.createRoot(document.getElementById('miNuevoComponente1'))
rootMiNuevoComponente1.render(<MiNuevoComponente1 />)

const rootMiNuevoComponente = ReactDOM.createRoot(document.getElementById('miNuevoComponente'))
rootMiNuevoComponente.render(<MiNuevoComponente />)

const rootMiNuevoComponente2 = ReactDOM.createRoot(document.getElementById('miNuevoComponente2'))
rootMiNuevoComponente2.render(<MiNuevoComponente2 />)

const rootMiNuevoComponente3 = ReactDOM.createRoot(document.getElementById('miNuevoComponente3'))
rootMiNuevoComponente3.render(<MiNuevoComponente3 />)

const rootMiNuevoComponente4 = ReactDOM.createRoot(document.getElementById('miNuevoComponente4'))
rootMiNuevoComponente4.render(<MiNuevoComponente4 />)

const rootMiNuevoComponente5 = ReactDOM.createRoot(document.getElementById('miNuevoComponente5'))
rootMiNuevoComponente5.render(<MiNuevoComponente5 />)