import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import myImage from './img/logo.jpeg';

export default function Example() {

    return (

        <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}

        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">

            <div className="text-center">

                <img src={myImage} alt="Mi imagen" style={{width: "450px", height: "300px"}} />

                <p className="text-base font-semibold text-indigo-600">By Juan Diego & Miguel Ángel <br></br>& Michael Leonardo </p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Alprigrama SAS</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">AlpriFly el arte de volar alto!</p>
                
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    
                    <a
                        href="login.php"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Iniciar Sesión
                    </a>

                </div>

            </div>

        </main>
        </>

    )

}
  