import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import myImage from './img/img_gallery/usuarios.PNG';
import myImage2 from './img/img_gallery/ventas.PNG';
import myImage3 from './img/img_gallery/facturacion.PNG';
import myImage4 from './img/img_gallery/produccion.PNG';
import myImage5 from './img/img_gallery/gestion_humana.PNG';
import myImage6 from './img/img_gallery/planta.PNG';
import myImage7 from './img/img_gallery/manto.PNG';
import myImage8 from './img/img_gallery/despachos.PNG';

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

const callouts = [

    {
      name: 'Usuarios',
      description: 'Work from home accessories',
      imageSrc: myImage,
      href: '#',
    },

    {
      name: 'Ventas / Mercadeo',
      description: 'Journals and note-taking',
      imageSrc: myImage2,
      href: '#',
    },

    {
      name: 'Facturación',
      description: 'Daily commute essentials',
      imageSrc: myImage3,
      href: '#',
    },
    {
    name: 'Producción',
    description: 'Work from home accessories',
    imageSrc: myImage4,
    href: '#',
    },

    {
    name: 'Gestión Humana',
    description: 'Journals and note-taking',
    imageSrc: myImage5,
    href: '#',
    },

    {
    name: 'Planta',
    description: 'Daily commute essentials',
    imageSrc: myImage6,
    href: '#',
    },
    {
        name: 'Mantenimiento Preventivo/Correctivo',
        description: 'Daily commute essentials',
        imageSrc: myImage7,
        href: '#',
    },
    {
        name: 'Despachos',
        description: 'Work from home accessories',
        imageSrc: myImage8,
        href: '#',
    },

]
  
export default function Example() {

    return (

        <div className="bg-gray-100">

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

                <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">

                    <h2 className="text-2xl font-bold text-gray-900">Collections</h2>
        
                    <div className="mt-6 space-y-12 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:space-y-0">

                        {callouts.map((callout) => (

                            <div key={callout.name} className="group relative">

                                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">

                                    <img
                                    src={callout.imageSrc}
                                    alt={callout.imageAlt}
                                    className="h-full w-full object-cover object-center"
                                    style={{ filter: 'blur(1.5px)' }}
                                    />
                                    
                                </div>
                                <h3 className="mt-6 text-sm text-gray-500">

                                    <a href={callout.href}>

                                        <span className="absolute inset-0" />
                                        {callout.name}

                                    </a>

                                </h3>

                                <p className="text-base font-semibold text-gray-900">{callout.description}</p><hr className='bg-gray-900 h-0.5'></hr><br></br>
                                
                            </div>

                        ))}

                    </div>

                </div>

            </div>

        </div>

    )
    
}
  