import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import myImage from './img/logo_alprigrama.png';

export default function Example() {

    return (

        <div className="bg-gray-900 text-white py-6 px-4">

            <div className="mx-auto max-w-2xl lg:max-w-4xl"></div>
            <img className="mx-auto h-20 w-20" src={myImage} alt="Logo" />

            <div className="text-center text-sm mt-7">
                © 2024 ALL PRINT GRAPHIC & MARKETING S.A.S. Todos los derechos reservados.<br></br><br></br>De AlpriFly con ❤️ para AlprigramaSAS :)
            </div><br></br><hr></hr>

            <div className="mt-4 flex justify-center space-x-4 text-sm">
                <a href="#" className="text-white hover:underline">Privacy Policy</a>
                <span>|</span>
                <a href="#" className="text-white hover:underline">Changelog</a>
            </div>

        </div>

    )

}